import React from 'react';
import styles from '../pages_styles/About.module.css'

function About() {
  return (
    <div className={styles.main}>
      <h1>Quem nós somos:</h1>
      <section>
      <p>This is the about page.</p>
      </section>
    </div>
  );
}

export default About;
