import React from "react";
import styles from '../pages_styles/Metodo.module.css'

function Metodo() {
    return (
        <div className={styles.main}>
        <h1>Hello world</h1>
        </div>
    )
}

export default Metodo;